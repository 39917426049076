<div id="wrapper">
  <div class="row border-bottom" id="navbar-wrapper">
    <nav class="navbar navbar-static-top" id="flowsheet-navbar" role="navigation" style="margin-bottom: 0">
      <div class="inline logo">
        <img src="/assets/img/suncor-optea-logo.png" alt="suncor logo" />
      </div>
      <div class="pull-right">
        <ul class="nav navbar-top-links" style="display: inline-block">
          <li>
            <a (click)="logout()"> <i class="fa fa-sign-out"></i> Log out </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>

  <nav class="navbar-default navbar-static-side" role="navigation">
    <div class="toggle-navigation">
      <a class="btn btn-primary" (click)="toggleNavigation()"><i class="fa fa-bars"></i> </a>
    </div>

    <div class="sidebar-collapse">
      <ul class="nav metismenu" id="side-menu">
        <li>
          <a routerLink="/flowsheet">
            <i class="fa fa-th-large"></i>
            <span class="nav-label">Flowsheet</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>

  <div id="page-wrapper" class="gray-bg">
    <div class="wrapper wrapper-content">
      <div class="row">
        <div class="col-xs-12">
          <div class="border-bottom">
            <h2>Changelog</h2>
          </div>
        </div>
      </div>
      <div class="row" style="margin-top: 20px">
        <div class="col-xs-12">
          <table class="table table-hover table-striped">
            <thead>
              <tr>
                <th style="width: 120px">Version</th>
                <th style="width: 120px">Engine Version</th>
                <th>Changes</th>
                <th>Release date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>2.82.0</td>
                <td>7572b9f2</td>
                <td>
                  <ul>
                    <li>IMP: Comments box customization</li>
                    <li>FIX: Bitumen conversion custom code persistence</li>
                  </ul>
                </td>
                <td>20/11/2024</td>
              </tr>
              <tr>
                <td>2.81.2</td>
                <td>5101be75</td>
                <td>
                  <ul>
                    <li>FIX: Remove forbidden characters in Excel report sheet names</li>
                  </ul>
                </td>
                <td>25/07/2024</td>
              </tr>
              <tr>
                <td>2.81.1</td>
                <td>5101be75</td>
                <td>
                  <ul>
                    <li>FIX: Fix a bug in split ratios for DRU and VAC blocks</li>
                    <li>
                      FIX: Improve runtime error detection in the frontend - consecutive duplicated errors are now
                      ignored
                    </li>
                  </ul>
                </td>
                <td>25/07/2024</td>
              </tr>
              <tr>
                <td>2.81.0</td>
                <td>6785abc2</td>
                <td>
                  <ul>
                    <li>IMP: Better runtime error detection in the frontend</li>
                    <li>FIX: Miscellaneous fixes in Constraints report</li>
                    <li>FIX: Fix a bug related to Upgrader inlet/outlet ports deletion</li>
                    <li>FIX: Fix problem when editing a unconnected Water Boiler</li>
                    <li>FIX: Fix deletion of 3rd level sub-flowsheet owners</li>
                    <li>FIX: Fix a bug in constraints report when a Upgrader with Subflowsheet is deleted</li>
                  </ul>
                </td>
                <td>23/07/2024</td>
              </tr>
              <tr>
                <td>2.80.1</td>
                <td>36fea39a</td>
                <td>
                  <ul>
                    <li>IMP: Display Unit Operation readiness status</li>
                    <li>IMP: Display composition on Streams</li>
                    <li>IMP: Added distillation profiles to hydrocarbon Streams</li>
                    <li>IMP: Add blend recipe to the Mixer</li>
                    <li>IMP: Updates to Bitumen Conversion</li>
                    <li>IMP: Better usability for Unit Operation connection ports</li>
                    <li>IMP: Better logic for showing Constraint Violation Report</li>
                    <li>IMP: Prevent adding assays with duplicated name</li>
                    <li>FIX: Fix Hydrogen Use Rate in Hydrotreater</li>
                    <li>FIX: Reorganization of Excel Report</li>
                    <li>FIX: Code Editor exiting only through Save or Cancel buttons</li>
                    <li>FIX: Miscellaneous fixes on Unit Operations</li>
                  </ul>
                </td>
                <td>05/07/2024</td>
              </tr>
              <tr>
                <td>2.71.1</td>
                <td>b87217e9</td>
                <td>
                  <ul>
                    <li>FIX: Miscellaneous updates and bug fixes in Multi-period mode</li>
                    <li>FIX: Miscellaneous updates and bug fixes in Property Windows</li>
                  </ul>
                </td>
                <td>24/02/2023</td>
              </tr>
              <tr>
                <td>2.71.1</td>
                <td>b87217e9</td>
                <td>
                  <ul>
                    <li>FIX: Miscellaneous updates and bug fixes in Multi-period mode</li>
                    <li>FIX: Miscellaneous updates and bug fixes in Property Windows</li>
                  </ul>
                </td>
                <td>24/02/2023</td>
              </tr>
              <tr>
                <td>2.71</td>
                <td>b87217e9</td>
                <td>
                  <ul>
                    <li>IMP: Background task for Case Study solving</li>
                    <li>IMP: Excel download for Multi-period results</li>
                    <li>IMP: Capacity Exceeded in Graph for Multi-period</li>
                    <li>IMP: Excel download for Case Study results</li>
                    <li>FIX: Miscellaneous updates and bug fixes in Multi-period mode</li>
                    <li>FIX: Miscellaneous updates and bug fixes in Property Windows</li>
                  </ul>
                </td>
                <td>17/02/2023</td>
              </tr>
              <tr>
                <td>2.70.1</td>
                <td>cc212bb04</td>
                <td>
                  <ul>
                    <li>IMP: Multi-period support</li>
                    <li>FIX: Miscellaneous updates and bug fixes in KPIs</li>
                    <li>FIX: Miscellaneous updates and bug fixes in Case Study</li>
                  </ul>
                </td>
                <td>19/01/2023</td>
                <td></td>
              </tr>
              <tr>
                <td>2.60</td>
                <td>6b75caca</td>
                <td>
                  <ul>
                    <li>IMP: Case Study</li>
                    <li>IMP: Add support for multiple upgraders</li>
                    <li>IMP: Add comments section in Unit Operations</li>
                    <li>FIX: Miscellaneous updates and bug fixes in KPIs</li>
                    <li>FIX: Miscellaneous updates and bug fixes in Unit Operations</li>
                  </ul>
                </td>
                <td>25/11/2022</td>
              </tr>
              <tr>
                <td>2.59</td>
                <td>11f317b9</td>
                <td>
                  <ul>
                    <li>IMP: Improve KPI manager organization</li>
                    <li>IMP: Expand list of available KPIs</li>
                    <li>IMP: Sort Optimization Settings Table by active constraint</li>
                    <li>IMP: Add Sum and Validation message to Water Header's distribution ratio variables</li>
                    <li>FIX: Keep inactive constraint values after solving</li>
                    <li>FIX: Fixes in constraints spreadsheet</li>
                  </ul>
                </td>
                <td>10/10/2022</td>
              </tr>
              <tr>
                <td>2.58</td>
                <td>4fcede10</td>
                <td>
                  <ul>
                    <li>IMP: Additional KPIs available from Streams, Upgrader Subflowsheet, Fuel Gas Flowsheet</li>
                    <li>IMP: Constraint ranking report now displays owner Flowsheet name</li>
                    <li>IMP: Add comments section to all blocks</li>
                    <li>FIX: Solve status displayed correctly in Fuel Gas and Steam and Power blocks</li>
                    <li>
                      FIX: Miscellaneous updates and bug fixes in Assay Manager, Property Windows and Upgrader Report
                    </li>
                  </ul>
                </td>
                <td>25/09/2022</td>
              </tr>
              <tr>
                <td>2.57</td>
                <td>7f146be9</td>
                <td>
                  <ul>
                    <li>IMP: Consolidate all spreadsheets</li>
                    <li>FIX: Update default GHG units in all blocks</li>
                    <li>FIX: Miscellaneous updates in Property Windows</li>
                  </ul>
                </td>
                <td>12/09/2022</td>
              </tr>
              <tr>
                <td>2.56</td>
                <td>eacbaaca</td>
                <td>
                  <ul>
                    <li>IMP: Flowsheet filter in Optimization Settings Table</li>
                    <li>IMP: Constraints Spreadsheet</li>
                    <li>IMP: Consolidate Assay and Constraints Spreadsheets</li>
                  </ul>
                </td>
                <td>04/09/2022</td>
              </tr>
              <tr>
                <td>2.55</td>
                <td>0372768a</td>
                <td>
                  <ul>
                    <li>IMP: Upgrader Report</li>
                    <li>IMP: New Excel Global Report</li>
                    <li>IMP: New H2s Spreadsheet</li>
                    <li>FIX: Summary Report - Include new Unit Operations and miscellaneous fixes</li>
                    <li>FIX: Miscellaneous updates in Property Windows</li>
                  </ul>
                </td>
                <td>25/08/2022</td>
              </tr>
              <tr>
                <td>2.50</td>
                <td>7b1f9e6d</td>
                <td>
                  <ul>
                    <li>IMP: Fuel Gas Flowsheet</li>
                    <li>IMP: New HRSG Unit Operation</li>
                    <li>IMP: New GHG Report</li>
                    <li>FIX: Fixes in Steam and Power Flowsheet</li>
                    <li>FIX: Miscellaneous fixes in User Interface</li>
                    <li>FIX: Miscellaneous fixes in other unit operations</li>
                    <li style="display: none"></li>
                  </ul>
                </td>
                <td>10/08/2022</td>
              </tr>
              <tr>
                <td>2.44</td>
                <td>551dfb2e</td>
                <td>
                  <ul>
                    <li>FIX: Fix bugs in Commodity Tank, Hydrotreater and Product Tank</li>
                    <li>FIX: Fix bugs in Steam and Power flowsheet</li>
                    <li>FIX: Improve compatibility with legacy case files</li>
                    <li>FIX: Miscellaneous fixes in other unit operations</li>
                  </ul>
                </td>
                <td>05/07/2022</td>
              </tr>

              <tr>
                <td>2.43.1</td>
                <td>38d24ab0</td>
                <td>
                  <ul>
                    <li>IMP: New Analysis Type - Gas</li>
                    <li>IMP: Add new fields to Gas Contributor Unit Operations</li>
                    <li>FIX: Rename Utilities to Steam and Power</li>
                    <li>FIX: Miscellaneous fixes in spreadsheets and property windows</li>
                  </ul>
                </td>
                <td>22/06/2022</td>
              </tr>
              <tr>
                <td>2.42</td>
                <td>54b26404</td>
                <td>
                  <ul>
                    <li>IMP: Add a visual indicator on Utilities Block for solved status</li>
                    <li>IMP: Update Summary report, now it includes charts and values for Steam</li>
                    <li>IMP: Gas Make, Fuel Gas Use and Gas Custom model fields on Unit Operations</li>
                    <li>IMP: Update Upgrader Steam Use constraints</li>
                    <li>IMP: Updates and changes on constraints for Upgrader Steam Use, Turbine and Valve</li>
                  </ul>
                </td>
                <td>08/06/2022</td>
              </tr>

              <tr>
                <td>2.41</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Implement new constraints in Boiler and Turbine</li>
                    <li>IMP: Improve Upgrader's subflowsheet palette</li>
                    <li>FIX: Miscellaneous fixes in Utilities Flowsheet</li>
                  </ul>
                </td>
                <td>20/05/2022</td>
              </tr>

              <tr>
                <td>2.40.2</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Sulfur Plant Unit Operation</li>
                    <li>FIX: Miscellaneous fixes in Utilities Flowsheet</li>
                  </ul>
                </td>
                <td>11/05/2022</td>
              </tr>

              <tr>
                <td>2.40.1</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Update in calculation engine</li>
                    <li>FIX: Minor fixes in icons</li>
                  </ul>
                </td>
                <td>09/05/2022</td>
              </tr>

              <tr>
                <td></td>
                <td>2.40</td>
                <td>
                  <ul>
                    <li>IMP: Utilities Flowsheet</li>
                    <li>IMP: Chart for Constraint Ranking</li>
                    <li>IMP: Mass Flowrate available on Stream property window</li>
                    <li>IMP: HGO Hydrotreater constraints available in Optimization Settings Table</li>
                    <li>IMP: Fluid Coker and HGO Hydrotreater available in Spreadsheet</li>
                  </ul>
                </td>
                <td>05/05/2022</td>
              </tr>

              <tr>
                <td></td>
                <td>2.32</td>
                <td>
                  <ul>
                    <li>FIX: Minor improvements for HGO Hydrotreater</li>
                    <li>FIX: Steam tab showing correct values on Utilities spreadsheet</li>
                    <li>IMP: Level 2 Unit Operations available on KPI manager</li>
                    <li>FIX: Cases with level 2 sent to Process Ecology after an error occurs working as expected</li>
                    <li>FIX: Login page showing more descriptive messages</li>
                    <li>FIX: Minor fixes for the Flowsheet diagram</li>
                  </ul>
                </td>
                <td>05/04/2022</td>
              </tr>
              <tr>
                <td>2.31</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: HGO Hydrotreater available on Upgrader subflowsheet</li>
                    <li>IMP: Pipe available on Upgrader subflowsheet</li>
                    <li>FIX: Minor improvements on Third Party Source block</li>
                    <li>FIX: Minor improvements and bug fixes</li>
                  </ul>
                </td>

                <td>28/02/2022</td>
              </tr>
              <tr>
                <td>2.30</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Mine subflowsheet</li>
                    <li>IMP: Background solving for cases</li>
                    <li>IMP: Fluid Coker available on Upgrader subflowsheet</li>
                    <li>FIX: Fix cloning issues</li>
                    <li>FIX: Minor improvements and bug fixes</li>
                  </ul>
                </td>
                <td>10/02/2022</td>
              </tr>
              <tr>
                <td>2.22</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Added property KPIs to streams</li>
                    <li>FIX: Fix cloning issues</li>
                  </ul>
                </td>
                <td>26/10/2021</td>
              </tr>
              <tr>
                <td>2.21</td>
                <td></td>
                <td>
                  <ul>
                    <li>FIX: Change palette in subflowsheets</li>
                    <li>FIX: Miscellaneous changes in calculations</li>
                  </ul>
                </td>
                <td>21/10/2021</td>
              </tr>
              <tr>
                <td>2.20</td>
                <td></td>
                <td>
                  <ul>
                    <li>FIX: Change extraction block icons</li>
                    <li>FIX: Fix stream creation quirks</li>
                  </ul>
                </td>
                <td>19/10/2021</td>
              </tr>
              <tr>
                <td>2.19</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Added Extraction block</li>
                  </ul>
                </td>
                <td>07/08/2021</td>
              </tr>
              <tr>
                <td>2.18</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Custom model for steam utility use estimation for units in Upgrader subflowsheet</li>
                    <li>IMP: Search function for unit operations in flowsheet</li>
                  </ul>
                </td>
                <td>26/08/2021</td>
              </tr>
              <tr>
                <td>2.17.1</td>
                <td></td>
                <td>
                  <ul>
                    <li>FIX: Fixed a problem regarding upgrader's maximum hydrogen production constraint</li>
                  </ul>
                </td>
                <td>02/07/2021</td>
              </tr>
              <tr>
                <td>2.17.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>
                      IMP: Added a dropdown to Hydrotreaters to set either "Properties" or "Blend Ratio" as the
                      constraint option
                    </li>
                    <li>IMP: Added "Minimum API" and "Maximum CCR" constraints to Hydrocracker and DCU</li>
                    <li>IMP: Added "Max flow rate" constraint to Mine, SAGD and Diluent sources</li>
                    <li>
                      FIX: Fixed an issue causing the current value for the hydrotreater's maximum nitrogen constraint
                      to appear empty
                    </li>
                    <li>
                      FIX: Fixed an issue that caused the Hydrogen use to report 0 for Hydrotreaters when the constraint
                      option was set to "Properties"
                    </li>
                  </ul>
                </td>
                <td>28/06/2021</td>
              </tr>
              <tr>
                <td>2.16.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Added an option in sankey chart to hide/show generic sinks.</li>
                    <li>IMP: Separated 'utilities' tab in spreadsheet control in 'H2S Production' and 'utilities'.</li>
                    <li>FIX: Fixed issue causing spreadsheet to freeze when the values were changed</li>
                  </ul>
                </td>
                <td>21/06/2021</td>
              </tr>
              <tr>
                <td>2.15.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>
                      IMP: Added a dropdown to Commodity Tanks to set either "Properties" or "Blend Ratio" as the
                      constraint option
                    </li>
                  </ul>
                </td>
                <td>16/06/2021</td>
              </tr>
              <tr>
                <td>2.14.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Added Utilities tab to Spreadsheet</li>
                  </ul>
                </td>
                <td>15/06/2021</td>
              </tr>
              <tr>
                <td>2.13.1</td>
                <td></td>
                <td>
                  <ul>
                    <li>FIX: Minor bug fixed for calculation of recovered diluent cost</li>
                  </ul>
                </td>
                <td>14/06/2021</td>
              </tr>
              <tr>
                <td>2.13.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Added utilities tab for Hydrotreater, Hydrocracker, DCU and VAC</li>
                    <li>IMP: Added Utilities tab to Upgrader</li>
                  </ul>
                </td>
                <td>09/06/2021</td>
              </tr>
              <tr>
                <td>2.12.1</td>
                <td></td>
                <td>
                  <ul>
                    <li>FIX: Solved an issue that caused the spreedsheet to freeze</li>
                  </ul>
                </td>
                <td>03/06/2021</td>
              </tr>
              <tr>
                <td>2.12.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Added new port "HGO" to Hydrocracker</li>
                  </ul>
                </td>
                <td>19/05/2021</td>
              </tr>
              <tr>
                <td>2.11.1</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Minor adjustments to ranking report to improve visibility</li>
                  </ul>
                </td>
                <td>12/04/2021</td>
              </tr>
              <tr>
                <td>2.11.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Added constraint ranking to summary report</li>
                  </ul>
                </td>
                <td>09/04/2021</td>
              </tr>
              <tr>
                <td>2.10.2</td>
                <td></td>
                <td>
                  <ul>
                    <li>FIX: Solved problem preventing the L2 sankey diagrams from loading</li>
                  </ul>
                </td>
                <td>25/03/2021</td>
              </tr>
              <tr>
                <td>2.10.1</td>
                <td></td>
                <td>
                  <ul>
                    <li>FIX: Solved problem preventing the bitumen conversion yield value to be greater than 100</li>
                  </ul>
                </td>
                <td>12/03/2021</td>
              </tr>
              <tr>
                <td>2.10.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Added Infeasibility report</li>
                    <li>IMP: Added support for multi-product bitumen conversion block</li>
                    <li>IMP: Sequential Linear Program (SLP) algorithm is set as default solver</li>
                  </ul>
                </td>
                <td>11/03/2021</td>
              </tr>
              <tr>
                <td>2.9.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Allowed for multiple inlets in Upgraders with L2 model enabled</li>
                  </ul>
                </td>
                <td>16/11/2020</td>
              </tr>
              <tr>
                <td>2.8.1</td>
                <td></td>
                <td>
                  <ul>
                    <li>FIX: Minor improvements and bug fixes</li>
                  </ul>
                </td>
                <td>10/11/2020</td>
              </tr>
              <tr>
                <td>2.8.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Added Hydrocracker unit operation</li>
                    <li>IMP: Added overview window</li>
                    <li>FIX: Minor improvements and bug fixes</li>
                  </ul>
                </td>
                <td>31/07/2020</td>
              </tr>
              <tr>
                <td>2.7.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Added Assay Manager</li>
                    <li>IMP: Added Assay Manager to the Spreadsheet Component</li>
                  </ul>
                </td>
                <td>21/07/2020</td>
              </tr>
              <tr>
                <td>2.6.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Added Sequential Linear Programming (SLP) as the default optimizer</li>
                  </ul>
                </td>
                <td>20/07/2020</td>
              </tr>
              <tr>
                <td>2.5.1</td>
                <td></td>
                <td>
                  <ul>
                    <li>FIX: Fixed a problem causing the flowsheet to crash after adding some unit operations</li>
                  </ul>
                </td>
                <td>13/07/2020</td>
              </tr>
              <tr>
                <td>2.5.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Improve creation of constraints in the calculation engine</li>
                    <li>IMP: Revenue and BTCF are shown in Upgrader L1 property even if L2 model is used</li>
                  </ul>
                </td>
                <td>08/07/2020</td>
              </tr>
              <tr>
                <td>2.4.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>
                      IMP: Added a new page to excel report and spreadsheet to list the commodity tanks within each
                      upgrader level 2 model
                    </li>
                  </ul>
                </td>
                <td>22/06/2020</td>
              </tr>
              <tr>
                <td>2.3.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Added a checkbox to hydrotreaters to disable FeedSlate</li>
                    <li>FIX: Fixed a problem causing some cases to return an error on solve</li>
                  </ul>
                </td>
                <td>17/06/2020</td>
              </tr>
              <tr>
                <td>2.2.1</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: General cleanup for multi-feed refineries</li>
                    <li>FIX: Fixed a problem causing stream tooltips to sometimes display wrong information</li>
                  </ul>
                </td>
                <td>08/06/2020</td>
              </tr>
              <tr>
                <td>2.2.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Added Range option for Feedslate constraint option</li>
                    <li>FIX: Fixed the bug for Hydrotreater constraints</li>
                  </ul>
                </td>
                <td>05/06/2020</td>
              </tr>
              <tr>
                <td>2.1.1</td>
                <td></td>
                <td>
                  <ul>
                    <li>
                      FIX: Solved issue that prevented any property window to be closed after opening a mixer or
                      splitter
                    </li>
                  </ul>
                </td>
                <td>02/06/2020</td>
              </tr>
              <tr>
                <td>2.1.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Allowed for multiple inlets in hydrotreater</li>
                    <li>IMP: Added Recipe constraint in hydrotreater</li>
                    <li>IMP: Added Minimum flow rate constraint to DRU, VAC, DCU, Hydrotreater, and Refinery blocks</li>
                    <li>
                      IMP: Modified economic calculations of Upgrader block (L1 Upgrader parameters are always used)
                    </li>
                  </ul>
                </td>
                <td>02/06/2020</td>
              </tr>
              <tr>
                <td>2.0.1</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Added Mass balance report in the excel report</li>
                    <li>IMP: Added property constraint indicators in Commodity tanks</li>
                    <li>IMP: Improved optimization settings table</li>
                  </ul>
                </td>
                <td>20/05/2020</td>
              </tr>
              <tr>
                <td>2.0.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Added Level 2 modeling for Upgrader</li>
                    <li>IMP: Added DRU, VAC, DCU, Hydrotreater blocks</li>
                    <li>IMP: Added product tanks and blending blocks</li>
                    <li>IMP: Updated characterization of assays</li>
                    <li>IMP: Added assay manager</li>
                  </ul>
                </td>
                <td>15/05/2020</td>
              </tr>
              <tr>
                <td>1.4.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Addition of Sankey chart.</li>
                    <li>IMP: Spreadsheet functionality for OPTEA.</li>
                  </ul>
                </td>
                <td>28/10/2019</td>
              </tr>

              <tr>
                <td>1.3.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>CHANGE: Insights page redesign.</li>
                    <li>IMP: Implemented grid/snap functionality in the diagram.</li>
                    <li>IMP: Dynamic resizing of Mixers and Splitters based on their number of connections.</li>
                    <li>IMP: Bulk property constraints for Refinery, Third party refinery and Upgrader.</li>
                  </ul>
                </td>
                <td>08/11/2018</td>
              </tr>
              <tr>
                <td>1.2.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Renewable block.</li>
                    <li>CHANGE: Default values for Bitumen conversion block.</li>
                    <li>CHANGE: Added "margin" fields and "Suncor profit percentage" to Third Party Refinery.</li>
                    <li>CHANGE: Added new block icons.</li>
                  </ul>
                </td>
                <td>17/10/2018</td>
              </tr>

              <tr>
                <td>1.1.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Bitumen conversion block.</li>
                    <li>IMP: Constraint violation indicator.</li>
                    <li>IMP: Insights page on Summary Report.</li>
                    <li>CHANGE: Model scaled to fit the screen upon loading.</li>
                    <li>CHANGE: Tabular format for KPI manager.</li>
                    <li>CHANGE: Smaller thickness for Streams with 0 Flowrate.</li>
                  </ul>
                </td>
                <td>09/10/2018</td>
              </tr>
              <tr>
                <td>1.0.1</td>
                <td></td>
                <td>
                  <ul>
                    <li>IMP: Added a function for sending cases to Process Ecology when solve fails.</li>
                    <li>IMP: Added Suncor OPTEA documentation as downloadable content.</li>
                    <li>FIX: Removed Sept2017 SUGoal from Cogen methodology options.</li>
                    <li>FIX: Improved overall diagram performance.</li>
                  </ul>
                </td>
                <td>11/07/2018</td>
              </tr>
              <tr>
                <td>1.0.0</td>
                <td></td>
                <td>
                  <ul>
                    <li>First release</li>
                  </ul>
                </td>
                <td>02/05/2018</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
